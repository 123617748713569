import React from "react";
import "./modules/_sufiscontribution.scss";

function Kamkaj() {
  return (
    <main className="vibhuti_sec">
      <div className="container sufis_group">
        <div className="row"></div>
      </div>
    </main>
  );
}

export default Kamkaj;